/* You can add global styles to this file, and also import other style files */

@import url("https://use.typekit.net/uhb8hro.css");
.main-container {
    padding-top: 2rem;
    margin-right: auto;
    margin-left: auto;
    max-width: 1600px;
    /* or 950px */
    min-height: calc(100vh);
}

.calligraphy {
    font-family: 'looking-flowers-script', sans-serif !important;
}

.deco {
    font-family: 'looking-flowers-deco', sans-serif !important;
}

.caps {
    font-family: 'looking-flowers-caps', sans-serif !important;
}


/* Importing Bootstrap SCSS file. */

@import '~bootstrap/scss/bootstrap';